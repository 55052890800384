import React from 'react';
import $ from 'jquery';
import confirmation from 'common/components/ConfirmationDialog';
import I18n from 'common/i18n';

const scope = 'screens.admin.connector.terminate_confirm';

function terminateConfirm(event) {
  event.preventDefault();
  const terminateButton = $(event.currentTarget);

  confirmation(
    <p>{I18n.t('message', { scope, connectionName: terminateButton.attr('data-server-name') })}</p>,
    'admin-connectors-confirmation-element',
    {
      agree: { text: I18n.t('accept_button', { scope }), className: 'btn-error' },
      header: I18n.t('title', { scope })
    }
  ).then((confirmResolution) => {
    if (confirmResolution.confirmed) {
      // make it so the next click will not trigger the confirmation modal
      terminateButton.unbind('click');
      // click to actually terminate the connection! button triggers: connector_controller#delete_connector
      terminateButton.trigger('click');
    }
  });
}

$(document).ready(() => {
  const flyoutTarget =   $('.flyout-target');

  flyoutTarget.on('mouseenter', (e) => {
    $(e.target).closest('.flyout-container').find('.flyout').removeClass('flyout-hidden');
  });


  flyoutTarget.on('mouseleave', (e) => {
    $(e.target).closest('.flyout-container').find('.flyout').addClass('flyout-hidden');
  });

  $('.terminateConnection').on('click', terminateConfirm);
});
